(function()
{
	'use strict';

	angular.module('atsc', [ 'ngCookies' ])
	.config(['$qProvider', function($qProvider)
	{
		// Suppress "Possibly unhandled rejection" errors. (We use deferred objects that do not always handle rejections.)
		$qProvider.errorOnUnhandledRejections(false);
	}])
	.config(['$httpProvider', function($httpProvider)
	{
		$httpProvider.interceptors.push([ '$q', '$rootScope', function($q, $rootScope)
		{
			return {
				'request': function(config)
				{
					if (!$rootScope.ajaxRequests)									{ $rootScope.ajaxRequests = 0; }

					if (!config.skipAjaxRequests)									{ $rootScope.ajaxRequests++; }

					return config;
				},
				'requestError': function(rejection)
				{
					if (!rejection.config.skipAjaxRequests)							{ $rootScope.ajaxRequests--; }

					return rejection;
				},
				'response': function(response)
				{
					if (!response.config.skipAjaxRequests)							{ $rootScope.ajaxRequests--; }

					return response;
				},
				'responseError': function(rejection)
				{
					if (!rejection.config.skipAjaxRequests)							{ $rootScope.ajaxRequests--; }

					return rejection;
				}
			};
		}]);
	}])
	.run(['$cookies', '$rootScope', function($cookies, $rootScope)
	{
		$(window).on('beforeDownload', function()
		{
			if (!$rootScope.ajaxRequests)											{ $rootScope.ajaxRequests = 0; }
			$rootScope.ajaxRequests++;
			$rootScope.$apply();
		});

		$(window).on('afterDownload', function()
		{
			$rootScope.ajaxRequests--;
			$rootScope.$apply();
		});
	}]);
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('atHref', function()
	{
		return {
			link: function(scope, element, attrs)
			{
				element.on('click', function()
				{
					if (attrs.atHrefReplace)	{ window.location.replace(attrs.atHref); }
					else						{ window.location.href = attrs.atHref; }
				})
			}
		};
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('atMargin', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('margin', attrs.atMargin);
				elem.removeAttr('at-margin');
			}
		}
	}])
	.directive('atMarginLeft', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('margin-left', attrs.atMarginLeft);
				elem.removeAttr('at-margin-left');
			}
		}
	}])
	.directive('atMarginRight', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('margin-right', attrs.atMarginRight);
				elem.removeAttr('at-margin-right');
			}
		}
	}])
	.directive('atMarginTop', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('margin-top', attrs.atMarginTop);
				elem.removeAttr('at-margin-top');
			}
		}
	}])
	.directive('atMarginBottom', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('margin-bottom', attrs.atMarginBottom);
				elem.removeAttr('at-margin-bottom');
			}
		}
	}]);
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('atPadding', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('padding', attrs.atPadding);
				elem.removeAttr('at-padding');
			}
		}
	}])
	.directive('atPaddingLeft', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('padding-left', attrs.atPaddingLeft);
				elem.removeAttr('at-padding-left');
			}
		}
	}])
	.directive('atPaddingRight', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('padding-right', attrs.atPaddingRight);
				elem.removeAttr('at-padding-right');
			}
		}
	}])
	.directive('atPaddingTop', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('padding-top', attrs.atPaddingTop);
				elem.removeAttr('at-padding-top');
			}
		}
	}])
	.directive('atPaddingBottom', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				elem.css('padding-bottom', attrs.atPaddingBottom);
				elem.removeAttr('at-padding-bottom');
			}
		}
	}]);
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('atRollover', function()
	{
		return {
			link: function(scope, element, attrs)
			{
				var normalState				= attrs.src;
				var rolloverState			= attrs.atRollover;

				element.on('mouseenter', function()
				{
					element.attr('src', rolloverState);
				}).on('mouseleave', function()
				{
					element.attr('src', normalState);
				});

				// Preload the rollover image.
				var img						= new Image();
				img.onload					= function(){ img = null; };
				img.src						= rolloverState;
			}
		}
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('atWidth', [ function()
	{
		return {
			restrict: 'A',
			link: function(scope, elem, attrs)
			{
				if (!elem.is('img') && !elem.is('td') && !elem.is('table') && !elem.is('safeid'))
				{
					elem.css('width', attrs.atWidth);
					elem.removeAttr('at-width');
				}
			}
		}
	}]);
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('capitalize', function()
	{
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, modelCtrl)
			{
				var capitalize = function(inputValue)
				{
					if (inputValue == undefined) { inputValue = ''; }

					var capitalized = inputValue.toUpperCase();

					if (capitalized !== inputValue)
					{
						modelCtrl.$setViewValue(capitalized);
						modelCtrl.$render();
					}

					return capitalized;
				}

				modelCtrl.$parsers.push(capitalize);
				capitalize(scope[attrs.ngModel]); // Capitalize initial value
			}
		}
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('codemirror', [ '$compile', '$parse', function($compile, $parse)
	{
		return {
			restrict: 'A',
			priority: 99,
			require: ['?ngModel', '^?mdInputContainer'],
			compile: function(element, attrs, ctrls)
			{
				return function postLink(scope, element, attrs, ctrls)
				{
					var editor;

					if (element.data('codemirror'))
					{
						editor = element.data('codemirror');
						editor.refresh();
					}
					else
					{
						editor = CodeMirror.fromTextArea(element[0],
						{
							lineNumbers: true,
							fixedGutter: false,
							tabSize: 4,
							indentUnit: 4,
							indentWithTabs: true,
							mode: attrs.mode || 'xml',
							autoRefresh: true,
							undoDepth: 100,
							lineWrapping: true,
							readOnly: (attrs.readonly == 'true' ? 'nocursor' : false),
							viewportMargin: Infinity
						});

						editor.on('change', function()
						{
							if (ctrls[0])
							{
								var value = editor.getValue();

								if (ctrls[0].$viewValue !== value)
								{
									scope.$evalAsync(function()
									{
										ctrls[0].$setViewValue(editor.getValue());
									});
								}
							}
						});

						element.data('codemirror', editor);
					}

					if (ctrls[0])
					{
						ctrls[0].$formatters.push(function(value)
						{
							if (angular.isUndefined(value) || value === null)
							{
								return '';
							}
							else if (angular.isObject(value) || angular.isArray(value))
							{
								throw new Error('Object or Array provided instead of String.');
							}
							return value;
						});

						ctrls[0].$render = function()
						{
							if (editor)
							{
								var safeViewValue = ctrls[0].$viewValue || '';
								if (editor.getValue() !== safeViewValue)
								{
									editor.setValue(safeViewValue);
								}
							}
						};
					}

					//if (ctrls[0])					{ ctrls[0].input = undefined; }
				};
			}
		}
	}]);
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('convertToBoolean', function()
	{
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel)
			{
				ngModel.$parsers.push(function(val)
				{
					if 		(val == 'true')		{ return 'true'; }
					else if (val == 'false')	{ return 'false'; }
					else						{ return null; }
				});
				ngModel.$formatters.push(function(val)
				{
					return '' + val;
				});
			}
		};
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('convertToNumber', function()
	{
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel)
			{
				ngModel.$parsers.push(function(val)
				{
					return parseInt(('' + val).replace(/,/g, '.'), 10);
				});
				ngModel.$formatters.push(function(val)
				{
					return '' + val;
				});
			}
		};
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('convertToFloat', function()
	{
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel)
			{
				ngModel.$parsers.push(function(val)
				{
					return parseFloat(('' + val).replace(/,/g, '.'));
				});
				ngModel.$formatters.push(function(val)
				{
					return '' + val;
				});
			}
		};
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('dynAbsPosition', [ '$compile', '$parse', '$timeout', '$mdDialog', 'LookupService', function($compile, $parse, $timeout, $mdDialog, LookupService)
	{
		return {
			restrict: 'A',
			priority: -1,
			compile: function(element, attrs, ctrls)
			{
				return function postLink(scope, element, attrs, ctrls)
				{
					var test = function()
					{
						var $element																					= $(element);
						var $relativeElement																			= $element.parent();

						while (!$relativeElement.is('html, body') && $relativeElement.css('position') == 'static')		{ $relativeElement = $relativeElement.parent(); }

						var offset																						= $element.offset();
						var top																							= null;
						var left																						= null;

						if (offset)
						{
							top																							= offset.top;
							left																						= offset.left;
						}

						if (!$relativeElement.is('html, body'))
						{
							offset																						= $relativeElement.offset();

							if (offset)
							{
								top																						= top - offset.top;
								left																					= left - offset.left;
							}
							else
							{
								top																						= null;
								left																					= null;
							}
						}

						if (top === null || left === null)
						{
							$timeout(test, 100);
						}
						else
						{
							$element.css({ 'position': 'absolute', 'top': top + 'px', 'bottom': '0px', 'left': left + 'px', 'right': '0px' });
						}
					}

					$timeout(test, 0);
				};
			}
		}
	}]);
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('fileread', function()
	{
		return {
			restrict: 'A',
			scope:
			{
				fileread: '=',
				filereadMax: '=?',
				filereadName: '=?',
			},
			link: function (scope, element, attributes)
			{
				element.bind('change', function (changeEvent)
				{
					if (changeEvent.target.files && changeEvent.target.files.length > 0 && changeEvent.target.files[0].size <= (scope.filereadMax || 0))
					{
						var reader = new FileReader();

						reader.onload = function (loadEvent)
						{
							scope.$apply(function()
							{
								scope.fileread				= loadEvent.target.result;
								scope.filereadName			= changeEvent.target.files[0].name;
							});
						}
						reader.readAsDataURL(changeEvent.target.files[0]);
					}
					else
					{
						scope.fileread = null;
					}
				});
			}
		};
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('formModel', [ '$compile', '$parse', '$timeout', function($compile, $parse, $timeout)
	{
		return {
			restrict: 'A',
			priority: 1000,
			terminal: true, // See also: https://docs.angularjs.org/guide/compiler#double-compilation-and-how-to-avoid-it
			require: ['^?mdInputContainer'],
			compile: function(element, attrs, ctrls)
			{
				var formModel												= attrs.formModel;
				var formValue												= attrs.formValue;
				var formOptions												= attrs.formModelOptions;
				var processValue											= attrs.formProcessValue != undefined;

				if		(formValue === 'true')								{ formValue = true; }
				else if (formValue === 'false')								{ formValue = false; }

				element.removeAttr('form-model');
				element.removeAttr('form-value');
				element.removeAttr('form-process-value');
				element.removeAttr('form-model-options');

				element.attr('ng-model',formModel);
				element.attr('ng-model-options', formOptions);

				return {
					pre: function(scope, element, attrs, ctrls)
					{
						var value												= formValue !== undefined ? formValue : element.val();

						if		(element[0].hasAttribute('convert-to-number'))	{ value = parseInt(('' + value).replace(/,/g, '.'), 10); }
						else if (element[0].hasAttribute('convert-to-float'))	{ value = parseFloat(('' + value).replace(/,/g, '.')); }
						else if (element[0].hasAttribute('convert-to-boolean'))	{ value = (('' + value) == 'true' ? true : (('' + value) == 'false' ? false : null)); }
						
						if (processValue)										{ value = $parse(value)(scope); }

						$parse(formModel).assign(scope, value);
					},
					post: function postLink(scope, element, attrs, ctrls)
					{
						$compile(element)(scope);

						if (ctrls[0])											{ ctrls[0].input = undefined; }
					}
				};
			}
		}
	}]);
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('lower', function()
	{
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, modelCtrl)
			{
				var lower = function(inputValue)
				{
					if (inputValue == undefined) { inputValue = ''; }

					var lowered = inputValue.toLowerCase();

					if (lowered !== inputValue)
					{
						modelCtrl.$setViewValue(lowered);
						modelCtrl.$render();
					}

					return lowered;
				}

				modelCtrl.$parsers.push(lower);
				lower(scope[attrs.ngModel]); // Lower initial value
			}
		}
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('unselectable', function()
	{
		var reject = function(){ return false; };

		return {
			restrict: 'C',
			link: function(scope, element, attrs)
			{
				element[0].onselectstart = reject;
			}
		};
	});
})();

(function(){
	'use strict';

	angular.module('atsc')
	.directive('select', [ function()
	{
		return {
			restrict: 'E',
			compile: function(element, attrs, ctrls)
			{
				return function postLink(scope, element, attrs, ctrls)
				{
					if (!element[0].hasAttribute('no-placeholder'))
					{
						var check = function()
						{
							if ($(element).val())
							{
								$(element).css('color', '');
							}
							else
							{
								$(element).css('color', $(element).attr('placeholder-color') || 'darkgrey');
							}
						};

						$(element).on('change', check);
						setTimeout(check, 0);
					}
				};
			}
		}
	}])
})();

(function(){
	'use strict';

	angular.module('atsc')
	.factory('FormValidatorFactory', ['$q', '$util', '$http', function FormValidatorFactory($q, $util, $http)
	{
		FormValidators.addProperty('$http', $http);
		FormValidators.addProperty('$q', $q);
		FormValidators.finalize();
		
		return {
			get: function(id){ return FormValidators.get(id); }
		};
	}]);
})();

(function(){
	'use strict';

	angular.module('atsc')
	.service('$util', [ '$q', function UtilitiesProvider($q)
	{
		var provider												= this;

		function tryRun(obj)
		{
			if (obj.run())											{ console.log('WARNING: No run() statement found for ' + obj.type); }
		}

		(function()
		{
			function loop(list, i, loop_callback, success_callback, error_callback)
			{
				if (i < list.length)								{ loop_callback(list[i], i).then(loop.bind(null, list, i + 1, loop_callback, success_callback, error_callback), error_callback); }
				else												{ success_callback(); }
			}

			function ForEach(list)
			{
				var loop_callback									= function(){ return $q(function(resolve, reject){ resolve(); }); };
				var success_callback								= function(){};
				var error_callback									= null;
				var running											= false;

				this.each = function(f)
				{
					loop_callback									= f;
					return this;
				};

				this.then = function(s, e)
				{
					success_callback								= s;
					error_callback									= e;
					return this;
				};

				this.run = function()
				{
					if (running)
					{
						return false;
					}
					else
					{
						running										= true;

						if (list && list.length > 0)				{ loop(list, 0, loop_callback, success_callback, error_callback || success_callback); }
						else										{ success_callback(); }

						return true;
					}
				};

				this.type											= 'ForEach';

				setTimeout(tryRun.bind(null, this), 1000);
			}

			provider.forEach										= function(list){ return new ForEach(list); };
		})();

		(function()
		{
			function loop(perform_callback, then_callback)			{ perform_callback().then(loop.bind(null, perform_callback, then_callback), then_callback); }

			function Loop()
			{
				var perform_callback								= function(){ return $q(function(next, exit){ exit(); }); };
				var then_callback									= function(){};
				var running											= false;

				this.perform = function(f)
				{
					perform_callback								= f;
					return this;
				};

				this.then = function(f)
				{
					then_callback									= f;
					return this;
				};

				this.run = function()
				{
					if (running)									{ return false; }
					else
					{
						running										= true;

						loop(perform_callback, then_callback);

						return true;
					}
				};

				this.type											= 'Loop';

				setTimeout(tryRun.bind(null, this), 1000);
			}

			provider.loop											= function(){ return new Loop(); };
		})();

		(function()
		{
			provider.equals = function(a, b)
			{
				if		(a === b)									{ return true; }
				else if ((a instanceof Array) && (b instanceof Array))
				{
					if (a.length == b.length)
					{
						for (var i = 0; i < a.length; i = i + 1)
						{
							if (!provider.equals(a[i], b[i]))		{ return false; }
						}

						return true;
					}
					else											{ return false; }
				}
				else if ((a instanceof Date) && (b instanceof Date)){ return a.getTime() == b.getTime(); }
				else if (typeof(a) == 'object' && typeof(b) == 'object')
				{
					// $$hashKey is an auto generated key which Angular assigns to arrays passed in ng-repeat directives.

					var key;
					for (key in a)
					{
						if (key != '$$hashKey')
						{
							if (!provider.equals(a[key], b[key]))	{ return false; }
						}
					}

					for (key in b)
					{
						if (key != '$$hashKey')
						{
							if (!provider.equals(a[key], b[key]))	{ return false; }
						}
					}

					return true;
				}
				else												{ return false; }
			};
		})();

		(function()
		{
			provider.toTrimmedStringOrNull = function(a)
			{
				if (a === undefined || a === null)					{ return null; }
				else												{ return ('' + a).trim(); }
			};
		})();

		(function()
		{
			provider.dataToBlob = function(dataURL)
			{
				var BASE64_MARKER									= ';base64,';
				var parts;
				var contentType;
				var raw;

				if (dataURL.indexOf(BASE64_MARKER) == -1)
				{
					parts											= dataURL.split(',');
					contentType										= parts[0].split(':')[1];
					raw												= decodeURIComponent(parts[1]);

					return new Blob([ raw ], { type: contentType });
				}
				else
				{
					parts											= dataURL.split(BASE64_MARKER);
					contentType										= parts[0].split(':')[1];
					raw												= window.atob(parts[1]);
					var rawLength									= raw.length;

					var uInt8Array									= new Uint8Array(rawLength);

					for (var i = 0; i < rawLength; ++i)				{ uInt8Array[i] = raw.charCodeAt(i); }

					return new Blob([uInt8Array], {type: contentType});
				}
			};
		})();
	}]);
})();

